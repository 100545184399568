<template>
  <div>
    <Nav active="products" @add="$router.push({ name: 'product' })" :showSave="false" />
    <div class="table">
      <div class="table-header">
        <div class="table-header-item">Name</div>
        <div class="table-header-item">Type</div>
        <div class="table-header-item">Vehicle Safe</div>
        <div class="table-header-item"></div>
        <div class="table-header-item"></div>
      </div>
      <div class="table-row" v-if="!isLoading.value" v-for="product in products" @click="$router.push({ name: 'product', params: { productid: product.productid } })">
        <div class="table-cell">
          <span>{{ product.name }}</span>
        </div>
        <div class="table-cell">
          <span>{{ product.type }}</span>
        </div>
        <div class="table-cell">
          <span>{{ product.vehiclesafe ? 'Yes' : 'No' }}</span>
        </div>
        <div class="table-cell"></div>
        <div class="table-cell actions" @click.stop="handleDelete(product.productid)"><img src="../assets/trash.svg" /></div>
      </div>
      <div class="table-row" v-if="isLoading.value">
        <div class="table-cell">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue';
import useProductsQuery from '../composables/useProductsQuery';
import useProductMutate from '../composables/useProductMutate';
export default {
  name: 'Products',
  components: {
    Nav,
  },
  setup() {
    const { products, refetchProducts, isLoading } = useProductsQuery;
    const { deleteProduct } = useProductMutate();
    return {
      products,
      refetchProducts,
      isLoading,
      deleteProduct,
    };
  },
  methods: {
    async handleDelete(productid) {
      if (!confirm('Are you sure you want to delete this product?')) return;
      const success = await this.deleteProduct(productid);
      if (success) this.refetchProducts();
    },
  },
  mounted() {
    this.refetchProducts();
  },
};
</script>

<style scoped lang="scss">
.table {
  .table-row {
    cursor: pointer;
  }
  & > div > div:nth-child(1) {
    width: 10%;
  }

  & > div > div:nth-child(2) {
    width: 10%;
  }

  & > div > div:nth-child(3) {
    width: 10%;
  }

  & > div > div:nth-child(4) {
    flex: 1;
  }

  & > div > div:nth-child(5) {
    width: 4rem;
  }
}
</style>
