import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const UPDATE_COLORS_MUTATION = gql`
  mutation UpdateColorsMutation($colors: [ColorInput!]!) {
    updateColors(colors: $colors) {
      error
      success
    }
  }
`;

const DELETE_COLOR_MUTATION = gql`
  mutation DeleteColorMutation($colorid: Int!) {
    deleteColor(colorid: $colorid) {
      error
      success
    }
  }
`;

export default function useColorsMutate() {
  const { mutate: updateMutate } = useMutation(UPDATE_COLORS_MUTATION);
  const { mutate: deleteMutate } = useMutation(DELETE_COLOR_MUTATION);

  async function updateColors(colors) {
    try {
      const { data } = await updateMutate({ colors });
      if (data.updateColors.success) return true;
      else throw new Error(data.updateColors.error);
    } catch (error) {
      console.error('Error updating colors:', error);
      alert(error);
      return false;
    }
  }

  async function deleteColor(colorid) {
    try {
      const { data } = await deleteMutate({ colorid });
      if (data.deleteColor.success) return true;
      else throw new Error(data.deleteColor.error);
    } catch (error) {
      console.error('Error deleting color:', error);
      alert(error);
      return false;
    }
  }

  return {
    updateColors,
    deleteColor,
  };
}
