<template>
  <div>
    <Nav active="patterns" @add="addPattern" @save="save" :showSave="true" :isDirty="isDirty" :isSaving="isSaving" />
    <div class="table">
      <div class="table-header">
        <div class="table-header-item">Name</div>
        <div class="table-header-item"></div>
        <div class="table-header-item"></div>
      </div>
      <div class="table-row" v-if="!isLoading.value" v-for="(pattern, index) in patterns">
        <div class="table-cell input-cell" :class="{ 'is-new': pattern.isNew }">
          <span>{{ pattern.name }}</span>
          <input type="text" v-model="pattern.name" @input="markDirty(pattern)" />
        </div>
        <div class="table-cell"></div>
        <div class="table-cell actions" @click="deletePattern(pattern.patternid, index)"><img src="../assets/trash.svg" /></div>
      </div>
      <div class="table-row" v-if="isLoading.value">
        <div class="table-cell">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue';
import usePatternsQuery from '../composables/usePatternsQuery';
import usePatternsMutate from '../composables/usePatternsMutate';

export default {
  name: 'Patterns',
  components: {
    Nav,
  },
  setup() {
    const { patterns, refetchPatterns, isLoading } = usePatternsQuery;
    const { updatePatterns, deletePattern } = usePatternsMutate();
    return {
      patterns,
      refetchPatterns,
      isLoading,
      updatePatterns,
      deletePattern,
    };
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    addPattern() {
      this.patterns.push({
        name: '',
        patternid: null,
        isNew: true,
      });
    },
    markDirty(pattern) {
      pattern.isDirty = true;
    },
    async save() {
      const invalidPattern = this.patterns.find((pattern) => !pattern.name);
      if (invalidPattern) {
        alert('All fields must be filled out before saving.');
        return;
      }
      this.isSaving = true;

      const newAndDirty = this.patterns.filter((pattern) => pattern.isNew || pattern.isDirty);
      const success = await this.updatePatterns(newAndDirty);
      if (success) {
        this.refetchPatterns();
      }
      this.isSaving = false;
    },
    async deletePattern(patternid, index) {
      if (!confirm('Are you sure you want to delete this pattern?')) return;
      const pattern = this.patterns[index];

      if (pattern.isNew) {
        this.patterns.splice(index, 1);
      } else {
        const success = await this.deletePattern(pattern.patternid);
        if (success) {
          this.patterns.splice(index, 1);
        }
      }
    },
  },
  computed: {
    isDirty() {
      return this.patterns.some((pattern) => pattern.isDirty) || this.patterns.some((pattern) => pattern.isNew);
    },
  },
  mounted() {
    this.refetchPatterns();
  },
};
</script>

<style scoped lang="scss">
.table {
  & > div > div:nth-child(1) {
    width: 16%;
    min-width: 14rem;
  }

  & > div > div:nth-child(2) {
    flex: 1;
  }

  & > div > div:nth-child(3) {
    width: 4rem;
  }
}
</style>
