import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { reactive, ref } from 'vue';

const surfaces = reactive([]);
const refetchSurfaces = ref(null);
const isLoading = ref(false);

const GET_SURFACES = gql`
  query GetSurfaces {
    getSurfaces {
      name
      surfaceid
      webname
    }
  }
`;

const getSurfaces = function () {
  try {
    const { result, loading, error, refetch, onResult } = useQuery(GET_SURFACES);

    onResult(() => {
      if (!result.value?.getSurfaces) return;
      const surfacesCopy = structuredClone(result.value.getSurfaces);
      surfacesCopy.sort((a, b) => a.name.localeCompare(b.name));
      surfaces.splice(0, surfaces.length, ...surfacesCopy);
    });

    isLoading.value = loading;
    refetchSurfaces.value = refetch;
  } catch (err) {
    console.error(err);
  }
};

export default {
  getSurfaces,
  surfaces,
  refetchSurfaces,
  isLoading,
};
