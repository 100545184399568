<template>
  <div class="size-options">
    <div class="container" :class="{ 'edit-mode': editMode }">
      <label>Size Options</label>
      <img class="action" src="@/assets/pencil-icon.svg" v-if="!editMode" @click="editMode = true" />
      <img class="action" src="@/assets/checkmark-icon.svg" v-else @click="editMode = false" />
      <ul v-if="!editMode">
        <li v-for="surface in size.productsizesurfaces">
          {{ lookupSurface(surface.surfaceid).name }}
          <ul>
            <li v-for="color in surface.productsizesurfacecolors">
              {{ lookupColor(color.colorid).name }}
            </li>
          </ul>
        </li>
      </ul>
      <ul v-else>
        <li v-for="surface in surfaces">
          <Checkbox :value="existsInSize(surface.surfaceid)" :label="surface.name" @update="updateSizeSurface(surface.surfaceid, $event)" />
          <ul>
            <li v-for="color in colors">
              <Checkbox :value="existsInSurface(color.colorid, surface.surfaceid)" :label="color.name" @update="updateSizeSurfaceColor(color.colorid, surface.surfaceid, $event)" />
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import useSurfacesQuery from '@/composables/useSurfacesQuery';
import useColorsQuery from '@/composables/useColorsQuery';
import Checkbox from '@/components/Checkbox.vue';

export default {
  name: 'SizeOptions',
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  components: {
    Checkbox,
  },
  setup() {
    const { surfaces } = useSurfacesQuery;
    const { colors } = useColorsQuery;

    return {
      surfaces,
      colors,
    };
  },
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    size: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    lookupSurface(surfaceid) {
      return this.surfaces.find((surface) => surface.surfaceid === surfaceid);
    },
    lookupColor(colorid) {
      return this.colors.find((color) => color.colorid === colorid);
    },
    existsInSize(surfaceid) {
      return this.size.productsizesurfaces.some((surface) => surface.surfaceid === surfaceid);
    },
    existsInSurface(colorid, surfaceid) {
      return this.size.productsizesurfaces.find((surface) => surface.surfaceid === surfaceid)?.productsizesurfacecolors.some((color) => color.colorid === colorid) || false;
    },
    updateSizeSurface(surfaceid, value) {
      if (value) {
        this.size.productsizesurfaces.push({ surfaceid, productsizesurfacecolors: [] });
      } else {
        this.size.productsizesurfaces = this.size.productsizesurfaces.filter((surface) => surface.surfaceid !== surfaceid);
      }
    },
    updateSizeSurfaceColor(colorid, surfaceid, value) {
      let surface = this.size.productsizesurfaces.find((surface) => surface.surfaceid === surfaceid);
      if (!surface) {
        this.updateSizeSurface(surfaceid, true);
        surface = this.size.productsizesurfaces.find((surface) => surface.surfaceid === surfaceid);
      }
      if (value) {
        if (!surface.productsizesurfacecolors) {
          surface.productsizesurfacecolors = [];
        }
        surface.productsizesurfacecolors.push({ colorid });
      } else {
        surface.productsizesurfacecolors = surface.productsizesurfacecolors.filter((color) => color.colorid !== colorid);
      }
    },
  },
  mounted() {
    console.log(this.size);
    if (!this.size.productsizesurfaces) {
      this.size.productsizesurfaces = [];
    }
  },
  watch: {
    'size.productsizesurfaces': () => {
      // Sort surfaces alphabetically by Name
      if (!this) return;

      this.size.productsizesurfaces.sort((a, b) => {
        const surfaceA = this.lookupSurface(a.surfaceid);
        const surfaceB = this.lookupSurface(b.surfaceid);
        return surfaceA.name.localeCompare(surfaceB.name);
      });

      // Sort colors within each surface alphabetically by name
      this.size.productsizesurfaces.forEach((surface) => {
        if (surface.productsizesurfacecolors) {
          surface.productsizesurfacecolors.sort((a, b) => {
            const aIsBlend = a.name.includes('Blend');
            const bIsBlend = b.name.includes('Blend');
            if (aIsBlend && !bIsBlend) return 1;
            if (!aIsBlend && bIsBlend) return -1;
            return a.name.localeCompare(b.name);
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.size-options {
  width: 100%;
  box-sizing: border-box;

  .container {
    width: 100%;
    max-width: 23rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    padding: 1.5rem 2.5rem;
    padding-left: 0;

    ul {
      margin: 0;
      font-size: 0.875rem;

      li {
        padding-bottom: 0.2rem;
        ul {
          padding-left: 1.2rem;
          list-style-type: disc;

          li {
            padding-top: 0.2rem;
          }
        }
      }
    }

    label {
      position: absolute;
      top: -0.32rem;
      left: 0.55rem;
      font-size: 0.625rem;
      color: rgb(0, 0, 0, 0.6);
      background: white;
      padding: 0 0.3rem;
      border-radius: 0.625rem;
    }

    .action {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }
  }

  .container.edit-mode {
    max-width: unset;

    & > ul {
      list-style-type: none;
      padding-left: 1rem;
      display: flex;
      flex-direction: row;
      gap: 2rem;

      li {
        list-style-type: none;
      }
    }
  }
}
</style>
