import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const UPDATE_SURFACES_MUTATION = gql`
  mutation UpdateSurfacesMutation($surfaces: [SurfaceInput!]!) {
    updateSurfaces(surfaces: $surfaces) {
      error
      success
    }
  }
`;

const DELETE_SURFACE_MUTATION = gql`
  mutation DeleteSurfaceMutation($surfaceid: Int!) {
    deleteSurface(surfaceid: $surfaceid) {
      error
      success
    }
  }
`;

export default function useSurfacesMutate() {
  const { mutate: updateMutate } = useMutation(UPDATE_SURFACES_MUTATION);
  const { mutate: deleteMutate } = useMutation(DELETE_SURFACE_MUTATION);

  async function updateSurfaces(surfaces) {
    try {
      const { data } = await updateMutate({ surfaces });
      if (data.updateSurfaces.success) return true;
      else throw new Error(data.updateSurfaces.error);
    } catch (error) {
      console.error('Error updating surfaces:', error);
      alert(error);
      return false;
    }
  }

  async function deleteSurface(surfaceid) {
    try {
      const { data } = await deleteMutate({ surfaceid });
      if (data.deleteSurface.success) return true;
      else throw new Error(data.deleteSurface.error);
    } catch (error) {
      console.error('Error deleting surface:', error);
      alert(error);
      return false;
    }
  }

  return {
    updateSurfaces,
    deleteSurface,
  };
}
