<template>
  <div>
    <Nav active="colors" @add="addColor" @save="save" :showSave="true" :isDirty="isDirty" :isSaving="isSaving" />
    <div class="table">
      <div class="table-header">
        <div class="table-header-item">Name</div>
        <div class="table-header-item">Web Name</div>
        <div class="table-header-item"></div>
        <div class="table-header-item"></div>
      </div>
      <div class="table-row" v-if="!isLoading.value" v-for="(color, index) in colors">
        <div class="table-cell input-cell" :class="{ 'is-new': color.isNew }">
          <span>{{ color.name }}</span>
          <input type="text" v-model="color.name" @input="markDirty(color)" />
        </div>
        <div class="table-cell input-cell" :class="{ 'is-new': color.isNew }">
          <span>{{ color.webname }}</span>
          <select v-model="color.webname" @change="markDirty(color)">
            <option value="Light Gray">Light Gray</option>
            <option value="Dark Gray">Dark Gray</option>
            <option value="Tan">Tan</option>
            <option value="Brown">Brown</option>
            <option value="Red">Red</option>
          </select>
        </div>
        <div class="table-cell"></div>
        <div class="table-cell actions" @click="deleteColor(color.colorid, index)"><img src="../assets/trash.svg" /></div>
      </div>
      <div class="table-row" v-if="isLoading.value">
        <div class="table-cell">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue';
import useColorsQuery from '../composables/useColorsQuery';
import useColorsMutate from '../composables/useColorsMutate';

export default {
  name: 'Colors',
  components: {
    Nav,
  },
  setup() {
    const { colors, refetchColors, isLoading } = useColorsQuery;
    const { updateColors, deleteColor } = useColorsMutate();
    return {
      colors,
      refetchColors,
      isLoading,
      updateColors,
      deleteColor,
    };
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    addColor() {
      this.colors.push({
        name: '',
        webname: '',
        colorid: null,
        isNew: true,
      });
    },
    markDirty(color) {
      color.isDirty = true;
    },
    async save() {
      const invalidColor = this.colors.find((color) => !color.name || !color.webname);
      if (invalidColor) {
        alert('All fields must be filled out before saving.');
        return;
      }
      this.isSaving = true;

      const newAndDirty = this.colors.filter((color) => color.isNew || color.isDirty);
      const success = await this.updateColors(newAndDirty);
      if (success) {
        this.refetchColors();
      }
      this.isSaving = false;
    },
    async deleteColor(colorid, index) {
      if (!confirm('Are you sure you want to delete this color?')) return;
      const color = this.colors[index];

      if (color.isNew) {
        this.colors.splice(index, 1);
      } else {
        const success = await this.deleteColor(color.colorid);
        if (success) {
          this.colors.splice(index, 1);
        }
      }
    },
  },
  computed: {
    isDirty() {
      return this.colors.some((color) => color.isDirty) || this.colors.some((color) => color.isNew);
    },
  },
  mounted() {
    this.refetchColors();
  },
};
</script>

<style scoped lang="scss">
.table {
  & > div > div:nth-child(1) {
    width: 13%;
    min-width: 12rem;
  }

  & > div > div:nth-child(2) {
    width: 13%;
    min-width: 12rem;
  }

  & > div > div:nth-child(3) {
    flex: 1;
  }

  & > div > div:nth-child(4) {
    width: 4rem;
  }
}
</style>
