<template>
  <div class="add-product">
    <div class="header">
      <h1>{{ productpatternid ? 'Edit' : 'Add' }} Product Pattern</h1>
      <div class="button-actions">
        <button @click="closeProduct" :disabled="isSaving" class="secondary-button">Close</button>
        <button @click="saveProduct" :disabled="isSaving">
          {{ isSaving ? 'Saving...' : 'Save & Close' }}
        </button>
      </div>
    </div>

    <section class="main-content">
      <div class="primary-input">
        <label>Product</label>
        <select v-model="productPattern.productid" @change="clearProductSizes()">
          <option v-for="product in products" :value="product.productid">{{ product.name }}</option>
        </select>
      </div>
      <div class="primary-input">
        <label>Pattern</label>
        <select v-model="productPattern.patternid">
          <option v-for="pattern in patterns" :value="pattern.patternid">{{ pattern.name }}</option>
        </select>
      </div>
      <div class="small-table">
        <div class="header">
          <div class="cell">Name</div>
          <div class="cell">%</div>
          <div class="cell"></div>
        </div>
        <div class="table-row" v-for="(productSize, index) in productPattern.productpatternsizes">
          <div class="cell">
            <select v-model="productSize.productsizeid">
              <option v-for="size in productSizeOptions" :value="size.productsizeid">{{ size.name }}</option>
            </select>
          </div>
          <div class="cell percent">
            <input type="text" v-model="productSize.percent" />
          </div>
          <div class="cell delete">
            <img src="@/assets/trash.svg" @click="deleteProductSize(index)" />
          </div>
        </div>
        <div class="table-row">
          <div class="cell">
            <img src="@/assets/plus.svg" @click="addProductSize()" />
          </div>
        </div>
        <div class="bottom-row">
          <div class="cell">Total</div>
          <div class="cell percent">{{ totalPercent }}%</div>
          <div class="cell"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import useProductsQuery from '@/composables/useProductsQuery';
import usePatternsQuery from '@/composables/usePatternsQuery';
import useProductPatternsQuery from '@/composables/useProductPatternsQuery';
import useProductPatternMutate from '@/composables/useProductPatternMutate';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const { products } = useProductsQuery;
    const { patterns } = usePatternsQuery;
    const { productpatterns, refetchProductPatterns } = useProductPatternsQuery;
    const { addProductPattern, updateProductPattern } = useProductPatternMutate();
    const productpatternid = parseInt(route.params.productpatternid);

    const productPattern = ref({});
    if (productpatternid) {
      productPattern.value = structuredClone(productpatterns.find((p) => p.productpatternid == productpatternid) || {});
      watch(productpatterns, () => {
        productPattern.value = structuredClone(productpatterns.find((p) => p.productpatternid == productpatternid) || {});
      });
    } else {
      productPattern.value = {
        productid: null,
        patternid: null,
        productpatternsizes: [{ productsizeid: null, percent: null }],
      };
    }

    return {
      products,
      patterns,
      productPattern,
      refetchProductPatterns,
      productpatternid,
      addProductPattern,
      updateProductPattern,
    };
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    productSizeOptions() {
      return this.productPattern.productid ? this.products.find((p) => p.productid == this.productPattern.productid).productsizes : [];
    },
    totalPercent() {
      if (!this.productPattern.productpatternsizes) return 0;
      let total = 0;
      this.productPattern.productpatternsizes.forEach((ps) => {
        const float = parseFloat(ps.percent);
        if (!isNaN(float)) total += float;
      });
      return total;
    },
  },
  methods: {
    async saveProduct() {
      this.parsePercents();
      if (!this.verifyFields()) return;
      this.isSaving = true;

      const newProductPattern = structuredClone(this.productPattern);

      if (this.productpatternid) {
        var success = await this.updateProductPattern(newProductPattern);
      } else {
        var success = await this.addProductPattern(newProductPattern);
      }

      if (!success) {
        this.isSaving = false;
        return;
      }

      this.refetchProductPatterns();
      this.$router.push('/productpatterns');
    },
    verifyFields() {
      if (!this.productPattern.productid) {
        alert('Please select a product');
        return false;
      }
      if (!this.productPattern.patternid) {
        alert('Please select a pattern');
        return false;
      }
      if (this.productPattern.productpatternsizes.length < 1) {
        alert('Please add at least one size');
        return false;
      }
      for (const size of this.productPattern.productpatternsizes) {
        if (!size.productsizeid) {
          alert('Please select a size for each row');
          return false;
        }
      }
      if (this.totalPercent != 100) {
        alert('Total percentage must be 100%');
        return false;
      }
      return true;
    },
    parsePercents() {
      this.productPattern.productpatternsizes.forEach((ps) => {
        ps.percent = parseFloat(ps.percent);
      });
    },
    closeProduct() {
      if (confirm('Are you sure you want to close without saving?')) {
        this.$router.push('/productpatterns');
      }
    },
    clearProductSizes() {
      this.productPattern.productpatternsizes = [];
      this.addProductSize();
    },
    addProductSize() {
      if (!this.productPattern.productpatternsizes) this.productPattern.productpatternsizes = [];
      this.productPattern.productpatternsizes.push({
        productsizeid: null,
        percent: null,
      });
    },
    deleteProductSize(index) {
      this.productPattern.productpatternsizes.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.add-product {
  .header {
    padding: 2rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 2.5rem;
      font-weight: 500;
      padding-left: 1.5rem;
    }

    .button-actions {
      display: flex;
      gap: 1.5rem;

      .secondary-button {
        background-color: gray;
        color: white;
      }
    }
  }
  .main-content {
    background-color: #fff;
    padding: 2.5rem 3rem;
    min-height: calc(100vh - 113px);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .primary-input {
      max-width: 25rem;
    }
  }
}

* {
  box-sizing: border-box;
}

.small-table {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 25rem;
  margin-top: 1rem;

  .header {
    display: flex;
    gap: 1rem;
    font-weight: 600;
    border-bottom: 1px solid #eeeeee;
    padding: 0;
    font-size: 0.9rem;

    .cell {
      padding: 0.3rem 1rem;
    }
  }
  .table-row {
    display: flex;
    font-size: 0.85rem;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    .cell {
      padding: 0 0.5rem;

      select {
        padding: 0.5rem 0.25rem;
        width: 90%;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
      }

      &.percent {
        padding: 0;

        input {
          padding: 0.5rem 0.5rem;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          width: 100%;
        }
      }

      img {
        cursor: pointer;
      }

      &.delete {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .bottom-row {
    display: flex;
    gap: 1rem;
    font-weight: 600;
    border-top: 1px solid #eeeeee;
    padding: 0;
    font-size: 0.9rem;

    .cell {
      padding: 0.3rem 1rem;
    }

    .percent {
      padding-left: 1.5rem;
    }
  }
}

.small-table > div > div:nth-child(1) {
  width: 75%;
}

.small-table > div > div:nth-child(2) {
  width: 15%;
}

.small-table > div > div:nth-child(3) {
  width: 10%;
}
</style>
