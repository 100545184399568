import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { reactive, ref } from 'vue';

const colors = reactive([]);
const refetchColors = ref(null);
const isLoading = ref(false);

const GET_COLORS = gql`
  query GetColors {
    getColors {
      name
      colorid
      webname
    }
  }
`;

const getColors = function () {
  try {
    const { result, loading, error, refetch, onResult } = useQuery(GET_COLORS);

    onResult(() => {
      if (!result.value?.getColors) return;
      const colorsCopy = structuredClone(result.value.getColors);
      colorsCopy.sort((a, b) => {
        const aIsBlend = a.name.includes('Blend');
        const bIsBlend = b.name.includes('Blend');
        if (aIsBlend && !bIsBlend) return 1;
        if (!aIsBlend && bIsBlend) return -1;
        return a.name.localeCompare(b.name);
      });
      colors.splice(0, colors.length, ...colorsCopy);
    });

    isLoading.value = loading;
    refetchColors.value = refetch;
  } catch (err) {
    console.error(err);
  }
};

export default {
  getColors,
  colors,
  refetchColors,
  isLoading,
};
