import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const ADD_PRODUCT_MUTATION = gql`
  mutation AddProductMutation($product: ProductInput!) {
    addProduct(product: $product) {
      error
      success
    }
  }
`;

const UPDATE_PRODUCT_MUTATION = gql`
  mutation UpdateProductMutation($product: ProductInput!) {
    updateProduct(product: $product) {
      error
      success
    }
  }
`;

const DELETE_PRODUCT_MUTATION = gql`
  mutation DeleteProductMutation($productid: Int!) {
    deleteProduct(productid: $productid) {
      error
      success
    }
  }
`;

const DELETE_PRODUCT_SIZE_MUTATION = gql`
  mutation DeleteProductSizeMutation($productsizeid: Int!) {
    deleteProductSize(productsizeid: $productsizeid) {
      error
      success
    }
  }
`;

export default function useProductMutate() {
  const { mutate: addMutate } = useMutation(ADD_PRODUCT_MUTATION);
  const { mutate: updateMutate } = useMutation(UPDATE_PRODUCT_MUTATION);
  const { mutate: deleteMutate } = useMutation(DELETE_PRODUCT_MUTATION);
  const { mutate: deleteProductSizeMutate } = useMutation(DELETE_PRODUCT_SIZE_MUTATION);
  async function addProduct(product) {
    try {
      const { data } = await addMutate({ product });
      if (data.addProduct.success) return true;
      else throw new Error(data.addProduct.error);
    } catch (error) {
      console.error('Error adding product:', error);
      alert(error);
      return false;
    }
  }

  async function updateProduct(product) {
    try {
      const { data } = await updateMutate({ product });
      if (data.updateProduct.success) return true;
      else throw new Error(data.updateProduct.error);
    } catch (error) {
      console.error('Error updating product:', error);
      alert(error);
      return false;
    }
  }

  async function deleteProduct(productid) {
    try {
      const { data } = await deleteMutate({ productid });
      if (data.deleteProduct.success) return true;
      else throw new Error(data.deleteProduct.error);
    } catch (error) {
      console.error('Error deleting product:', error);
      alert(error);
      return false;
    }
  }

  async function deleteProductSize(productsizeid) {
    try {
      const { data } = await deleteProductSizeMutate({ productsizeid });
      if (data.deleteProductSize.success) return true;
      else throw new Error(data.deleteProductSize.error);
    } catch (error) {
      console.error('Error deleting product size:', error);
      alert(error);
      return false;
    }
  }

  return {
    addProduct,
    updateProduct,
    deleteProduct,
    deleteProductSize,
  };
}
