<template>
  <div>
    <Nav active="productpatterns" @add="$router.push({ name: 'productpattern' })" />
    <div class="table">
      <div class="table-header">
        <div class="table-header-item">Product</div>
        <div class="table-header-item">Pattern</div>
        <div class="table-header-item">Product Ratios</div>
        <div class="table-header-item"></div>
        <div class="table-header-item"></div>
      </div>
      <div class="table-row" v-if="!isLoading.value" v-for="productpattern in productpatterns" @click="$router.push({ name: 'productpattern', params: { productpatternid: productpattern.productpatternid } })">
        <div class="table-cell">
          <span>{{ productpattern.productname }}</span>
        </div>
        <div class="table-cell">
          <span>{{ productpattern.patternname }}</span>
        </div>
        <div class="table-cell">
          <span>{{ getRatios(productpattern.productpatternsizes, productpattern.productid) }}</span>
        </div>
        <div class="table-cell"></div>
        <div class="table-cell actions" @click.stop="handleDelete(productpattern.productpatternid)"><img src="../assets/trash.svg" /></div>
      </div>
      <div class="table-row" v-if="isLoading.value">
        <div class="table-cell">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue';
import useProductPatternsQuery from '../composables/useProductPatternsQuery';
import usePatternsQuery from '../composables/usePatternsQuery';
import useProductsQuery from '../composables/useProductsQuery';
import useProductPatternMutate from '../composables/useProductPatternMutate';
import { computed } from 'vue';

export default {
  name: 'ProductPatterns',
  components: {
    Nav,
  },
  setup() {
    const { productpatterns, refetchProductPatterns, isLoading } = useProductPatternsQuery;
    const { deleteProductPattern } = useProductPatternMutate();
    const { patterns } = usePatternsQuery;
    const { products } = useProductsQuery;
    const productPatternComputed = computed(() => {
      return productpatterns.map((productpattern) => {
        return {
          ...productpattern,
          productname: products.find((p) => p.productid === productpattern.productid)?.name || '',
          patternname: patterns.find((p) => p.patternid === productpattern.patternid)?.name || '',
        };
      });
    });

    return {
      productpatterns: productPatternComputed,
      products,
      refetchProductPatterns,
      isLoading,
      deleteProductPattern,
    };
  },
  methods: {
    async handleDelete(productpatternid) {
      if (confirm('Are you sure you want to delete this product pattern?')) {
        await this.deleteProductPattern(productpatternid);
        this.refetchProductPatterns();
      }
    },
    getRatios(productpatternsizes, productid) {
      return productpatternsizes.map((ps) => `${ps.percent}% ${this.getSizeName(productid, ps.productsizeid)}`).join(', ');
    },
    getSizeName(productid, productsizeid) {
      return this.products.find((p) => p.productid === productid)?.productsizes.find((s) => s.productsizeid === productsizeid)?.name || '';
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  .table-row {
    cursor: pointer;
  }
  & > div > div:nth-child(1) {
    width: 13%;
    min-width: 12rem;
  }

  & > div > div:nth-child(2) {
    width: 13%;
    min-width: 12rem;
  }

  & > div > div:nth-child(3) {
    width: 50%;
    min-width: 12rem;
  }

  & > div > div:nth-child(4) {
    flex: 1;
  }

  & > div > div:nth-child(5) {
    width: 4rem;
  }
}
</style>
