import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const UPDATE_PATTERNS_MUTATION = gql`
  mutation UpdatePatternsMutation($patterns: [PatternInput!]!) {
    updatePatterns(patterns: $patterns) {
      error
      success
    }
  }
`;

const DELETE_PATTERN_MUTATION = gql`
  mutation DeletePatternMutation($patternid: Int!) {
    deletePattern(patternid: $patternid) {
      error
      success
    }
  }
`;

export default function usePatternsMutate() {
  const { mutate: updateMutate } = useMutation(UPDATE_PATTERNS_MUTATION);
  const { mutate: deleteMutate } = useMutation(DELETE_PATTERN_MUTATION);

  async function updatePatterns(patterns) {
    try {
      const { data } = await updateMutate({ patterns });
      if (data.updatePatterns.success) return true;
      else throw new Error(data.updatePatterns.error);
    } catch (error) {
      console.error('Error updating patterns:', error);
      alert(error);
      return false;
    }
  }

  async function deletePattern(patternid) {
    try {
      const { data } = await deleteMutate({ patternid });
      if (data.deletePattern.success) return true;
      else throw new Error(data.deletePattern.error);
    } catch (error) {
      console.error('Error deleting pattern:', error);
      alert(error);
      return false;
    }
  }

  return {
    updatePatterns,
    deletePattern,
  };
}
