<template>
  <nav>
    <div class="title-bar">
      <h1>Product Management</h1>
      <div class="button-actions">
        <button v-if="showSave && isDirty" @click="$emit('save')" :disabled="isSaving">{{ isSaving ? 'Saving...' : 'Save' }}</button>
        <button @click="$emit('add')">Add</button>
      </div>
    </div>
    <ul>
      <li :class="{ active: active === 'products' }" @click="navigate('/products')">Products</li>
      <li :class="{ active: active === 'surfaces' }" @click="navigate('/surfaces')">Surfaces</li>
      <li :class="{ active: active === 'colors' }" @click="navigate('/colors')">Colors</li>
      <li :class="{ active: active === 'productpatterns' }" @click="navigate('/productpatterns')">Product Patterns</li>
      <li :class="{ active: active === 'patterns' }" @click="navigate('/patterns')">Patterns</li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['title', 'active', 'showSave', 'isDirty', 'isSaving'],
  emits: ['add', 'save'],
  name: 'Nav',
  methods: {
    navigate(path) {
      if (this.isDirty) {
        if (confirm('You have unsaved changes. Are you sure you want to navigate?')) {
          this.$router.push(path);
        }
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  .title-bar {
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 2.5rem;
      font-weight: 500;
      padding-left: 1.5rem;
    }

    .button-actions {
      display: flex;
      gap: 1.5rem;
    }
  }

  ul {
    list-style-type: none;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #dedede;

    li {
      padding: 1.2rem 2.5rem;
      text-decoration: none;
      color: black;
      font-weight: 600;
      color: #717070;
      text-transform: uppercase;
      display: block;
      cursor: pointer;

      &.active {
        background: #e9edf1;
        border-bottom: 2px solid #2a4b7c;
        color: #2a4b7c;
        margin-bottom: -2px;
      }
    }
  }
}
</style>
