import { createApp, provide, h } from 'vue';
import App from './App.vue';
import router from './router';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';

const urlParams = new URLSearchParams(window.location.search);
const key = urlParams.get('key');

const httpLink = createHttpLink({
  // uri: `http://localhost:4000/graphql?key=${key}`,
  uri: `https://api.westerninterlock.com/graphql?key=${key}`,
});

const cache = new InMemoryCache({
  addTypename: false,
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
})
  .use(router)
  .mount('#app');
