<template>
  <div class="add-product">
    <div class="header">
      <h1>{{ productid ? 'Edit' : 'Add' }} Product</h1>
      <div class="button-actions">
        <button @click="closeProduct" :disabled="isSaving" class="secondary-button">Close</button>
        <button @click="saveProduct" :disabled="isSaving">
          {{ isSaving ? 'Saving...' : 'Save & Close' }}
        </button>
      </div>
    </div>

    <section v-if="isLoading.value || !product" class="product-information">
      <h2>Loading...</h2>
    </section>

    <section v-if="!isLoading.value && product" class="product-information">
      <h2>Product Information</h2>
      <div class="form-group">
        <div class="primary-input">
          <label for="name">Name</label>
          <input type="text" id="name" v-model="product.name" />
        </div>
        <div class="primary-input">
          <label for="type">Type</label>
          <select id="type" v-model="product.type">
            <option value="paver">Paver</option>
            <option value="wall">Wall</option>
            <option value="cap">Cap</option>
            <option value="rotundo">Rotundo</option>
            <option value="firefeature">Fire Feature</option>
          </select>
        </div>
        <div class="primary-input" v-if="product.type === 'paver'">
          <label for="vehiclesafe">Vehicle Safe</label>
          <select id="vehiclesafe" v-model="product.vehiclesafe">
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>
        </div>
      </div>
    </section>

    <div class="section-divider" v-if="!isLoading.value"></div>

    <section v-if="!isLoading.value && product" class="sizes-information">
      <div class="size-header">
        <h2>Sizes, Surfaces, and Colors</h2>
        <button @click="addSize">Add</button>
      </div>
      <div class="size" v-for="(size, index) in sortedSizes">
        <div class="size-settings">
          <div class="size-fields">
            <div class="primary-input">
              <label>Name</label>
              <input type="text" v-model="size.name" />
            </div>
            <div class="primary-input small">
              <label>Pieces / SqFt</label>
              <input type="number" v-model="size.sqft" />
            </div>
            <div class="primary-input small">
              <label>Length (in)</label>
              <input type="number" v-model="size.length" />
            </div>
            <div class="primary-input small">
              <label>Width (in)</label>
              <input type="number" v-model="size.width" />
            </div>
            <div class="primary-input">
              <label>Dimensions (mm)</label>
              <input type="text" v-model="size.dimensions" />
            </div>
            <div class="primary-input small">
              <label>Weight (lb)</label>
              <input type="number" v-model="size.weight" />
            </div>
            <div class="primary-input small">
              <label>Pcs / Layer</label>
              <input type="number" v-model="size.perlayer" />
            </div>
            <div class="primary-input small">
              <label>Pcs / Pallet</label>
              <input type="number" v-model="size.perpallet" />
            </div>
            <div class="primary-input small">
              <label>Can Be Border</label>
              <select v-model="size.canbeborder">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
          <div class="size-actions">
            <img src="../assets/duplicate-icon.svg" @click="duplicateSize(size)" />
            <img src="../assets/trash-icon.svg" @click="deleteSize(size)" />
            <div class="sort-arrows">
              <img src="../assets/sort-up.svg" @click="sortSize(size, 'up')" />
              <img src="../assets/sort-down.svg" @click="sortSize(size, 'down')" />
            </div>
          </div>
        </div>
        <SizeOptions v-model="product.productsizes[index]" />
      </div>
    </section>
  </div>
</template>

<script>
import useProductsQuery from '@/composables/useProductsQuery';
import useProductMutate from '@/composables/useProductMutate';
import SizeOptions from '@/components/SizeOptions.vue';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    SizeOptions,
  },
  setup() {
    const route = useRoute();
    const { addProduct, updateProduct, deleteProductSize } = useProductMutate();
    const { products, refetchProducts, isLoading } = useProductsQuery;
    const productid = parseInt(route.params.productid);

    const product = ref({});
    if (productid) {
      product.value = structuredClone(products.find((p) => p.productid == productid) || {});
      watch(products, () => {
        product.value = structuredClone(products.find((p) => p.productid == productid) || {});
      });
    } else {
      product.value = {
        name: '',
        type: 'paver',
        vehiclesafe: false,
        productsizes: [
          {
            name: '',
            canbeborder: false,
            length: null,
            width: null,
            dimensions: '',
            weight: null,
            perlayer: null,
            perpallet: null,
            sortorder: 1,
            isNew: true,
          },
        ],
      };
    }

    return {
      products,
      refetchProducts,
      isLoading,
      addProduct,
      updateProduct,
      deleteProductSize,
      product,
      productid,
    };
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    async saveProduct() {
      if (!this.verifyFields()) return;
      this.isSaving = true;

      const newProduct = structuredClone(this.product);

      for (const key in newProduct) {
        if (newProduct[key] === '') {
          newProduct[key] = null;
        }
      }

      if (newProduct.productsizes) {
        newProduct.productsizes.forEach((size) => {
          for (const key in size) {
            if (size[key] === '') {
              size[key] = null;
            }
          }
        });
      }

      if (this.productid) {
        var success = await this.updateProduct(newProduct);
      } else {
        var success = await this.addProduct(newProduct);
      }

      if (!success) {
        this.isSaving = false;
        return;
      }

      this.refetchProducts();
      this.$router.push('/products');
    },
    verifyFields() {
      if (!this.product.name.trim()) {
        alert('Please enter a name');
        return false;
      }

      if (!this.product?.productsizes?.length) {
        alert('Please add at least one size');
        return false;
      }

      if (!this.product.productsizes.every((size) => size.name.trim())) {
        alert('Please enter a name for all sizes');
        return false;
      }

      if (!this.product.productsizes.every((size) => size.productsizesurfaces && size.productsizesurfaces.length > 0)) {
        alert('Please add at least one surface option for each size');
        return false;
      }

      if (!this.product.productsizes.every((size) => size.productsizesurfaces.every((surface) => surface.productsizesurfacecolors && surface.productsizesurfacecolors.length > 0))) {
        alert('Please add at least one color option for each surface in every size');
        return false;
      }

      return true;
    },
    closeProduct() {
      if (confirm('Are you sure you want to close without saving?')) {
        this.$router.push('/products');
      }
    },
    addSize() {
      if (!this.product.productsizes) {
        this.product.productsizes = [];
      }
      this.product.productsizes.push({
        name: '',
        canbeborder: false,
        length: null,
        width: null,
        dimensions: '',
        weight: null,
        perlayer: null,
        perpallet: null,
        sortorder: this.product.productsizes.length + 1,
        isNew: true,
      });
    },
    duplicateSize(size) {
      if (!this.product.productsizes) {
        this.product.productsizes = [];
      }
      let newSize = structuredClone(size);
      newSize.sortorder = this.product.productsizes.length + 1;
      newSize.isNew = true;
      newSize = this.deleteIds(newSize);

      this.product.productsizes.push(newSize);
    },
    async deleteSize(size) {
      if (size.isNew) {
        this.product.productsizes = this.product.productsizes.filter((s) => s !== size);
      } else {
        if (!confirm('Are you sure you want to delete this size?')) return;
        try {
          const success = await this.deleteProductSize(size.productsizeid);
          if (success) {
            this.product.productsizes = this.product.productsizes.filter((s) => s !== size);
          }
        } catch (error) {
          alert(error);
        }
      }
    },
    sortSize(size, direction) {
      const sorted = this.sortedSizes;
      const index = sorted.indexOf(size);
      if (direction === 'up' && index > 0) {
        const previousSize = sorted[index - 1];
        const temp = previousSize.sortorder;
        previousSize.sortorder = size.sortorder;
        size.sortorder = temp;
      } else if (direction === 'down' && index < sorted.length - 1) {
        const nextSize = sorted[index + 1];
        const temp = nextSize.sortorder;
        nextSize.sortorder = size.sortorder;
        size.sortorder = temp;
      }
    },
    deleteIds(obj) {
      if (!obj || typeof obj !== 'object') return obj;

      delete obj.productsizeid;
      delete obj.productsizesurfaceid;
      delete obj.productsizesurfacecolorid;

      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((item) => this.deleteIds(item));
        } else if (typeof obj[key] === 'object') {
          this.deleteIds(obj[key]);
        }
      }

      return obj;
    },
  },
  computed: {
    sortedSizes() {
      return this.product.productsizes?.sort((a, b) => a.sortorder - b.sortorder) || [];
    },
  },
};
</script>

<style scoped lang="scss">
.add-product {
  .header {
    padding: 2rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 2.5rem;
      font-weight: 500;
      padding-left: 1.5rem;
    }

    .button-actions {
      display: flex;
      gap: 1.5rem;

      .secondary-button {
        background-color: gray;
        color: white;
      }
    }
  }
  .product-information {
    background-color: #fff;
    padding: 2.5rem 3rem;

    .form-group {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      flex-wrap: wrap;
      width: 100%;
      max-width: 18rem;

      .primary-input {
        width: 100%;
      }
    }
  }
  .section-divider {
    height: 2rem;
  }
  .sizes-information {
    background-color: #fff;

    .size-header {
      padding: 1.5rem 3rem;
      padding-right: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #e0e0e0;
      button {
        font-size: 0.9rem;
      }
      h2 {
        padding-bottom: 0;
      }
    }
    .size {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      border-bottom: 1px solid #e0e0e0;
      gap: 1.5rem;
      padding: 2.5rem 3rem;
      padding-right: 1.5rem;

      .size-settings {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1.5rem;

        .size-fields {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          gap: 1.5rem;
          flex-wrap: wrap;

          .primary-input {
            flex: 1 1 20%;
            min-width: 13rem;

            &.small {
              min-width: 6rem;
              flex: 1 1 5%;
              max-width: 9rem;
            }
          }
        }
        .size-actions {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          align-items: flex-start;
          box-sizing: border-box;
          min-width: 12rem;
          justify-content: flex-end;

          img {
            width: 2.3rem;
            cursor: pointer;
          }

          .sort-arrows {
            display: flex;
            flex-direction: column;
            gap: 0;

            img {
              width: 1.2rem;
            }
          }
        }
      }
    }
  }
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 500;
  color: #2a4b7c;
  padding-bottom: 1.5rem;
}
</style>
