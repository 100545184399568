import gql from 'graphql-tag';
import { useQuery } from '@vue/apollo-composable';
import { reactive, ref } from 'vue';

const productpatterns = reactive([]);
const refetchProductPatterns = ref(null);
const isLoading = ref(false);

const GET_PRODUCT_PATTERNS = gql`
  query GetProductPatterns {
    getProductPatterns {
      productpatternid
      productid
      patternid
      productpatternsizes {
        productsizeid
        percent
      }
    }
  }
`;

const getProductPatterns = function () {
  try {
    const { result, loading, error, refetch, onResult } = useQuery(GET_PRODUCT_PATTERNS);

    onResult(() => {
      if (!result.value?.getProductPatterns) return;
      const productpatternsCopy = structuredClone(result.value.getProductPatterns);
      productpatterns.splice(0, productpatterns.length, ...productpatternsCopy);
    });

    isLoading.value = loading;
    refetchProductPatterns.value = refetch;
  } catch (err) {
    console.error(err);
  }
};

export default {
  getProductPatterns,
  productpatterns,
  refetchProductPatterns,
  isLoading,
};
