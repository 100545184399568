import { createRouter, createWebHistory } from 'vue-router';
import Products from '../views/Products.vue';
import Patterns from '../views/Patterns.vue';
import Surfaces from '../views/Surfaces.vue';
import Colors from '../views/Colors.vue';
import ProductPatterns from '../views/ProductPatterns.vue';
import Product from '../views/Product.vue';
import ProductPattern from '../views/ProductPattern.vue';

const routes = [
  {
    path: '/',
    redirect: '/products',
  },
  {
    path: '/products',
    name: 'products',
    component: Products,
  },
  {
    path: '/patterns',
    name: 'patterns',
    component: Patterns,
  },
  {
    path: '/productpatterns',
    name: 'productpatterns',
    component: ProductPatterns,
  },
  {
    path: '/surfaces',
    name: 'surfaces',
    component: Surfaces,
  },
  {
    path: '/colors',
    name: 'colors',
    component: Colors,
  },
  {
    path: '/product/:productid?',
    name: 'product',
    component: Product,
  },
  {
    path: '/productpattern/:productpatternid?',
    name: 'productpattern',
    component: ProductPattern,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
