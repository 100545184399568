<template>
  <div class="checkbox">
    <img @click="$emit('update', false)" v-show="value" src="@/assets/checkedbox.svg" />
    <img @click="$emit('update', true)" v-show="!value" src="@/assets/uncheckedbox.svg" />
    <label @click="$emit('update', !value)">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  emits: ['update'],
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 0.2rem;
  }

  label {
    cursor: pointer;
    font-size: 0.875rem;
  }
}
</style>
