import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const ADD_PRODUCT_PATTERN_MUTATION = gql`
  mutation AddProductPatternMutation($productpattern: ProductPatternInput!) {
    addProductPattern(productpattern: $productpattern) {
      error
      success
    }
  }
`;

const UPDATE_PRODUCT_PATTERN_MUTATION = gql`
  mutation UpdateProductPatternMutation($productpattern: ProductPatternInput!) {
    updateProductPattern(productpattern: $productpattern) {
      error
      success
    }
  }
`;

const DELETE_PRODUCT_PATTERN_MUTATION = gql`
  mutation DeleteProductPatternMutation($productpatternid: Int!) {
    deleteProductPattern(productpatternid: $productpatternid) {
      error
      success
    }
  }
`;

export default function useProductPatternMutate() {
  const { mutate: addMutate } = useMutation(ADD_PRODUCT_PATTERN_MUTATION);
  const { mutate: updateMutate } = useMutation(UPDATE_PRODUCT_PATTERN_MUTATION);
  const { mutate: deleteMutate } = useMutation(DELETE_PRODUCT_PATTERN_MUTATION);

  async function addProductPattern(productpattern) {
    try {
      const { data } = await addMutate({ productpattern });
      if (data.addProductPattern.success) return true;
      else throw new Error(data.addProductPattern.error);
    } catch (error) {
      console.error('Error adding product pattern:', error);
      alert(error);
      return false;
    }
  }

  async function updateProductPattern(productpattern) {
    try {
      const { data } = await updateMutate({ productpattern });
      if (data.updateProductPattern.success) return true;
      else throw new Error(data.updateProductPattern.error);
    } catch (error) {
      console.error('Error updating product pattern:', error);
      alert(error);
      return false;
    }
  }

  async function deleteProductPattern(productpatternid) {
    try {
      const { data } = await deleteMutate({ productpatternid });
      if (data.deleteProductPattern.success) return true;
      else throw new Error(data.deleteProductPattern.error);
    } catch (error) {
      console.error('Error deleting product pattern:', error);
      alert(error);
      return false;
    }
  }

  return {
    addProductPattern,
    updateProductPattern,
    deleteProductPattern,
  };
}
